import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {PublicOfferPageResource} from "../../../generated/hid/resources";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {PublicFormatPageData} from "../../../generated/hid/data";
import {Meta, Title} from "@angular/platform-browser";
import {BehaviorSubject, interval} from "rxjs";
import {LocalStorageService} from "../../utility/services/local-storage.service";
import {MediaService} from "../../utility/services/media.service";
import {MetaService} from "../../utility/services/meta.service";
import {environment} from "../../../environments/environment";
import {delay} from "../../utility/services/utils.service";
import {AnalyticsService} from "../../utility/services/gdprconsent/analytics.service";

@Component({
  selector: 'app-landing-pages',
  templateUrl: './landing-pages.component.html',
  styleUrl: './landing-pages.component.scss'
})
export class LandingPagesComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild("offerImage", {static: true}) imageWrapper: ElementRef;

  routingUrl: string | null = null;

  page: PublicFormatPageData = <PublicFormatPageData>{
    content: ''
  };

  forcedSplitId: number;
  loading = new BehaviorSubject<boolean>(true);
  imageSrc = '';

  completeRedirectUrl: string = null
  documentReady = false;

  trackingClasses: string = '';

  showSidebar: boolean = true;

  altText: string = "Alternativ-Text für Angebotsbild"

  constructor(
    private offerPageResource: PublicOfferPageResource,
    private analyticsService: AnalyticsService,
    private localStorageService: LocalStorageService,
    private route: ActivatedRoute,
    private mediaService: MediaService,
    private router: Router,
    private meta: Meta,
    private elem: ElementRef,
    private titleService: Title,
    private metaService: MetaService,
  ) {

    route.params.subscribe((params: Params) => {
      this.routingUrl = params['routingUrl'];

      route.queryParams
        .subscribe((queryParams: Params) => {
          this.forcedSplitId = queryParams['spid'];
          this.loadPage()
        })
    });

    //If user did arrive here without any params, bring up the last known. This is a workaround because analytics is retarded and would have lost the last click
    if (!route.snapshot.params['utm_source'] && !route.snapshot.params['utm_campaign'] && !route.snapshot.params['utm_medium'] && !route.snapshot.params['utm_content']) {
      (route.queryParams as any)['test'] = "12";
    }
  }

  //TOdo generate complete redirectUrl after load
  ngOnInit() {
    interval(200).subscribe((id: number) => {
      this.documentReady = document.readyState == 'complete';
      if (this.documentReady) {
        this.loading.next(false);
        this.setUpPageContentCta()
        clearInterval(id)
      }
    });

  }


  //Marketing can give elements in their custom HTML the class page-content-cta that initiates the checkout
  setUpPageContentCta(){
    this.elem.nativeElement.querySelectorAll('.page-content-cta').forEach((e:any) => {
      if(this.page.cmsOfferPageType === 'RedirectOfferPage') {
        this.prepareRedirectLink()
        e.href = this.completeRedirectUrl
      }
    });
  }

  prepareRedirectLink(){
    this.completeRedirectUrl = this.page.redirectUrl + this.localStorageService.generateUrlTailFromTracking(this.page.redirectUrl)
  }


  ngAfterViewInit() {
    this.setUpPageContentCta();
  }

  ngOnDestroy() {
    this.metaService.resetRobotsTag();
  }

  loadPage() {

    if (this.routingUrl === "null") return;
    this.offerPageResource.getFormatOfferPage(this.routingUrl, {forcedSplitId: this.forcedSplitId})
      .then((res: PublicFormatPageData) => {

          this.page = res;
          this.altText = res.altText
          this.showSidebar = res.showSidebar

          // set html title
          if (this.page.titleTag !== null && this.page.titleTag !== '') {
            this.titleService.setTitle(this.page.titleTag);
          } else {
            this.titleService.setTitle("Talentagent")
          }

          // set html meta title, description
          this.meta.removeTag('name ="title"');
          this.meta.removeTag('name ="description"');
          this.metaService.resetRobotsTag();
          this.metaService.setRobotsTag(this.page.indexPage);
          if (this.page.metaDescription != null) {
            if (this.meta.getTag("description") == null) {
              this.meta.addTag({name: 'description', content: this.page.metaDescription});
            } else {
              this.meta.updateTag({name: 'description', content: this.page.metaDescription});
            }
          }

          this.generateTrackingClasses();
          this.imageSrc = this.buildContainerImageUrl(this.imageWrapper, this.page.imageContainerId)

          //Async loading of html binding produces weird browser behaviour. Also if you dont give it a view MS, html is not bound yet at this point
          delay(500).then(() => {
            window.scrollTo(0, 0);
            this.injectTrackingFunctions()
          })

        }
      )
  }

  buildContainerImageUrl(ref: ElementRef, containerId: number) {
    let height = 270
    let width = 250
    if(ref) {
      height =  ref.nativeElement.clientHeight
      width =ref.nativeElement.clientWidth
    }
    return environment.apiUrlHidCms + '/images/containers/' + containerId + '?height=' + height + '&width=' + width
  }

  generateTrackingClasses() {
    const res = [];
    if (this.page.cronosFormatId) {
      res.push('ita_format_' + this.page.cronosFormatId);
    }
    res.push('ita_format-split_' + this.page.selectedSplitId);
    // }
    for (let cl of res) {
      this.trackingClasses += cl + ' '
    }
  }

  injectTrackingFunctions() {
    this.analyticsService.reportPageView(true)
    let ctaList = document.getElementsByClassName('page-content-cta')
    for(let i = 0; i < ctaList.length; i++){
      ctaList[i].addEventListener('click', (event) =>
        this.analyticsService.initiateCheckout(event, this.page, this.completeRedirectUrl));
    }
  }
}
