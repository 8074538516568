import {Injectable} from '@angular/core';
import {Router} from "@angular/router";
import {DataLayerService} from "@piwikpro/ngx-piwik-pro";
import {environment} from "../../../../environments/environment";
import {LocalStorageService} from "../local-storage.service";
import {PublicFormatPageData} from "../../../../generated/hid/data";

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  environment = environment;

  constructor(
    private router: Router,
    private localStorageService: LocalStorageService,
    private dataLayerService: DataLayerService
  ) {
  }


  initGtm(){
    (<any>window).dataLayer =  (<any>window).dataLayer || [];
    (<any>window).dataLayer.push({
      originalLocation: document.location.protocol + '//' +
        document.location.hostname +
        document.location.pathname +
        document.location.search
    });

    (function (w: any, d: any, s: any, l: any, i: any) {
      w[l] = w[l] || [];
      w[l].push({
        'gtm.start':
          new Date().getTime(), event: 'gtm.js'
      });
      var f = d.getElementsByTagName(s)[0],
        j:any = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : '';
      j.async = true;
      j.src =
        'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
      f.parentNode.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', environment.gtmId);
  }

  initiateCheckout(event: Event | null, page: PublicFormatPageData, completeRedirectUrl: string) {
      event.preventDefault();
      this.reportCheckoutTrackingEvent('InitiateCheckout', page);
      window.location.href = completeRedirectUrl;
      return;
  }

  reportCheckoutTrackingEvent(eventName?: string, page?:PublicFormatPageData){
    let event: any = {
      event: eventName ? eventName : 'InitiateCheckout',
      eventName: eventName ? eventName : 'InitiateCheckout',
      formatId:  page?.cronosFormatId ? page.cronosFormatId : 2166,
      pageSpid: page?.selectedSplitId ? page.selectedSplitId : null,
    };
    this.pushEvent(event);
  }

  reportDemoRequest(context: string){
    let event = {
      event: 'DemoRequest',
      context: context
    };
    this.pushEvent(event);
  }

    reportRequestDemoCta(context: string, email: string){
        let event = {
            event: 'RequestDemoCall',
            context: context,
            isPrivateMail: this.isPrivateMail(email)
        };
      this.pushEvent(event);
    }

    isPrivateMail(email: string): boolean {
        const domain = email.split("@")[1];
        return [
            "gmail.com",
            "web.de",
            "live.de",
            "yahoo.de",
            "icloud.com",
            "gmx.de",
            "gmx.net",
            "hotmail.de",
            "hotmail.com",
            "outlook.de",
            "outlook.com"
        ].includes(domain);
    }

  reportCaseStudyClick(context: string, partner: string){
    let event = {
      event: 'CaseStudyClick',
      context: context,
      partner: partner
    };
    this.pushEvent(event);
  }

    reportCareerGuideCtaClick(onSideBar: boolean){
        let event = {
            event: 'KarriereguideBlogCheckout ',
            onSideBar: onSideBar
        };
        this.pushEvent(event);
    }


  //If you want to exclude paths, you can use .forRoot pattern on module import and exclude the pathes here
  reportPageView(sendToPiwik: boolean = true){
    const url = this.router.url;




    const pageView = {
      event: 'VirtualPageview',
      virtualPagePath:   url,
      consent_facebook: this.localStorageService.gdprConsent.consent_facebook,
      consent_ga: this.localStorageService.gdprConsent.consent_ga,
      consent_linkedIn: this.localStorageService.gdprConsent.consent_linkedIn,
      consent_pinterest:this.localStorageService.gdprConsent.consent_pinterest,
      consent_google_ads: this.localStorageService.gdprConsent.consent_google_ads,
      consent_tiktok: this.localStorageService.gdprConsent.consent_tiktok,
      consent_piwik: this.localStorageService.gdprConsent.consent_piwik
    };

    this.pushEvent(pageView);
  }

  private pushEvent(event: object){
    // (<any>window).dataLayer.push(event);
    this.dataLayerService.push(event)
  }
}
