<div id="ita_offer-page-wrapper"  class="flex row center content-padding hid-offer-page content-wrapper ita_format-split-{{page.selectedSplitId}}">
  <div [ngClass]="{'page-content': showSidebar, 'full-page-content': !showSidebar, 'fullscreen': page.fullScreen}">
    <div [innerHTML]="page.content | safeHtml"></div>
  </div>

  <div *ngIf="showSidebar" id="side-bar" class="flex column start-center">
    <div class="image-wrapper">
      <img id="offer-image" [src]="imageSrc" #offerImage>
      <div id="fake-image" class="fake" *ngIf="loading.getValue()"></div>
    </div>

    <a *ngIf="page.cmsOfferPageType === 'RedirectOfferPage'"
       [className]="'cta-button ita_initiate-checkout ' + trackingClasses"
       target="_blank"
       [href]="completeRedirectUrl">      {{page.ctaText}}</a>

    <div id="side-text" class="flex column" [innerHTML]="page.sideText | safeHtml"></div>
  </div>

  <div id="mobile-cta" class="flex column center">
    <a *ngIf="page.cmsOfferPageType === 'RedirectOfferPage'"
       [className]="'cta-button ita_initiate-checkout ' + trackingClasses"
       [href]="completeRedirectUrl">
      <span *ngIf="!loading.getValue()">{{page.ctaText}}</span>
    </a>
    <div class="mobile-contact-data"></div>
  </div>
</div>
